<template>
<div class="card">
  <div class="card-image">
    <figure class="image" >
      <img
        :src="GetImage(record.thumbnail)"
        style="object-fit: contain; height:25vh; max-width:90%; margin:auto"
        class="pt-3"
      />
    </figure>
  </div>
  <div class="card-content">
      <p class="is-title is-size-5">{{record.title}}</p>
      <p class="is-text is-size-7">{{record.date}}</p>
  </div>
    <footer class="card-footer">
    <a href="#" class="card-footer-item is-text has-blue-text"  style="text-decoration: none" @click.prevent="edit" v-if="loggedIn">Edit</a>
    <a href="#" class="card-footer-item is-text has-red-text"  style="text-decoration: none" @click.prevent="deleteRecord" v-if="loggedIn">Delete</a>
  </footer>
</div>
</template>

<script>
export default {
  props: ["record"],
  data() {
    return {};
  },
  computed: {
    loggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    GetImage: function (relativePath) {
      if (relativePath == null || relativePath == "") return require("../../assets/smhm_logo.jpg");
      return relativePath;
    },
    edit(){
      this.$emit('editRecord', this.record);
    },
    deleteRecord(){
      //console.log("hi")
      this.$emit('deleteRecord',this.record);
    }
  },
};
</script>