<template>
  <div class="mt-6">
    <!-- Top row with save button -->

    <div v-show="ShowStickers">
      <stickers
          class="is-overlay"
          v-show="ShowStickers"
          :name="curLocation.id"
          :qr_code="curLocation.qr_code"
          @close="ShowStickers = false"
        >
        </stickers>
    </div>
    <div v-show="!ShowStickers">
    <div class="panel-heading text-center columns mt-3 mx-0 p-0 is-vcentered" style="max-width:100%">
      <div class="column"></div>
      <p class="column is-header">
        Edit Location
      </p>
      <div class="column" >
        <button
          class="button is-text is-success is-sharp mr-3"
          style="text-decoration: none"
          @click="handleSubmit"
          :class="{ disabled: !canSubmit }"
        >
          Save Changes
        </button>
        <button
          class="button is-link is-text is-sharp"
          style="text-decoration: none"
          @click="back"
        >
          Back
        </button>
      </div>
    </div>

    <div v-if="loggedIn" class="columns is-centered mx-0" style="max-width:100%">
      <div class="column is-three-quarters-desktop">
        <!-- Button to view printable sticker -->
        <div class="column">
          <button
            class="button is-primary is-text"
            style="text-decoration: none"
            @click="ShowStickers = true"
          >
            View printable stickers
          </button>
        </div>
        <!-- Check boxes -->
        <section class="section">
          <label class="checkbox mx-6 is-text">
            <input
              ref="mobile"
              type="checkbox"
            />
            Show on mobile
          </label>
          <label class="checkbox mx-6 is-text">
            <input
              ref="table"
              type="checkbox"
            />
            Show on table
          </label>
        </section>

        <!-- Address Info -->
        <div class="columns">
          <div class="column has-text-left">
            <div class="is-flex is-flex-direction-row">
              <label class="is-text">Address</label>
              <div class="is-text has-red-text ml-3">
                (Required if no lat/long)
              </div>
            </div>
            <p class="is-size-7 is-italic is-text mb-3">
              Formatting guidelines can be found here:
              <a
                href="https://docs.mapbox.com/help/troubleshooting/address-geocoding-format-guide/"
                >MapBox Geocoding</a
              >
            </p>
            <b-input
              ref="addr"
              :id="'addr'"
              :placeholder="'123 Main St Boston MA 02111'"
              :maxLength="100"
              @valChanged="valueChanged"
            ></b-input>
          </div>
          <div class="column is-1 is-text">
            <p>OR</p>
          </div>
          <div class="column has-text-left">
            <div class="is-flex is-flex-direction-row">
              <label class="is-text">Latitude and Longitude</label>
              <div class="is-text has-red-text ml-3">
                (Required if no address)
              </div>
            </div>

            <b-input
              ref="lat"
              :id="'lat'"
              :placeholder="'Enter latitude here'"
              :maxLength="30"
              @valChanged="valueChanged"
              :isNumberOnly="true"
            ></b-input>

            <b-input
              ref="long"
              :id="'long'"
              :placeholder="'Enter longitude here'"
              :maxLength="30"
              @valChanged="valueChanged"
              :isNumberOnly="true"
              class="mt-2"
            ></b-input>
          </div>
        </div>

        <!-- Related Records -->
        <div class="section">
          <button
            class="button is-link is-text"
            style="text-decoration: none"
            @click="TryToAddRecord"
            title="Add new record to location"
          >
            Add Record
          </button>
          <div v-if="ShowAddRecord" class="mt-5">
            <AddRecordForm
              :isEditForm="false"
              :collection_id="collectionId"
              :location_id="curLocation.id"
              @close="ShowAddRecord = false"
            />
          </div>
          <div class="section">
            <div class="columns is-multiline">
              <div
                class="column is-one-third-desktop is-full-mobile"
                v-for="record in records"
                :key="record.id"
              >
                <record-thumbnail
                  :record="record"
                  @editRecord="EditRecord"
                  @deleteRecord="TryToDeleteRecord"
                />
              </div>
            </div>
          </div>

          <div v-if="ShowEditRecord" class="mt-5">
            <AddRecordForm
              :isEditForm="true"
              :collection_id="collectionId"
              :location_id="curLocation.id"
              :recordToEdit="selectedRecord"
              @close="ShowEditRecord = false"
            />
          </div>
        </div>

        <b-modal
          :visible="showModal"
          heading="Deleting Record"
          :subHeading="
            'Are you sure you want to delete this record?\n' +
            selectedRecord.title
          "
          confirmText="Delete"
          colorStyle="danger"
          @cancel="showModal = false"
          @confirm="ForceDeleteRecord"
        />
      </div>
    </div>
    <div v-else>
      <p>Unauthorized. You must be logged in to view this page.</p>
    </div>

    </div>
  </div>
</template>

<script>
//import SaveButton from "../components/SaveButton.vue";
import RecordThumbnail from "../records/RecordThumbnail.vue";
import Stickers from "./Stickers.vue";
import AddRecordForm from "../records/AddRecordForm.vue";
import BModal from "../../components/b-modal.vue";
import BInput from "../../components/b-input.vue";
import {delete_file} from "../../services/s3-signed-upload";
export default {
  components: { Stickers, RecordThumbnail, AddRecordForm, BModal, BInput },
  computed: {
    loggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    storeRecords: function () {
      return this.$store.state.records.all.items || [];
    },
    collectionId: function () {
      return this.$route.params.id;
    },
    CanAddRecords: function () {
      return this.records.length < 3;
    },
  },
  watch: {
    storeRecords() {
      //console.log("records changed");
      //console.log(this.$store.state.records.all.items.length);
      this.records = this.storeRecords;
      if(this.records.length > 0) this.records.sort((a, b) => (a.date > b.date ? 1 : -1));
    },
  },
  data() {
    return {
      curLocation: {},
      records: [],
      ShowStickers: false,
      ShowAddRecord: false,
      ShowEditRecord: false,
      showModal: false,
      selectedRecord: {},
      canSubmit: true,
    };
  },
  async mounted() {
    this.$store.commit("AWAIT_RESPONSE", true);
    this.curLocation = await this.$store.dispatch(
      "locations/getLocationFromRoute",
      "/collections/" +
        this.$route.params.id +
        "/locations/" +
        this.$route.params.location_id
    );

    if (this.curLocation != null) {
      this.$refs.addr.setValue(this.curLocation.address);
      this.$refs.lat.setValue(this.curLocation.latitude);
      this.$refs.long.setValue(this.curLocation.longitude);
      this.$refs.mobile.checked = this.curLocation.show_mobile;
      this.$refs.table.checked = this.curLocation.show_table;

      await this.$store.dispatch("records/getAll", this.curLocation);
    }

    this.$store.commit("AWAIT_RESPONSE", false);
  },
  methods: {
    async handleSubmit() {
      try{
        this.curLocation.address = this.$refs.addr.getValue();
        this.curLocation.latitude = this.$refs.lat.getValue();
        this.curLocation.longitude = this.$refs.long.getValue();
        this.curLocation.show_mobile = this.$refs.mobile.checked;
        this.curLocation.show_table = this.$refs.table.checked;

        this.$store.commit("AWAIT_RESPONSE", true);
        await this.$store.dispatch("locations/update", this.curLocation);
        this.$store.commit("AWAIT_RESPONSE", false);

        //show success
        this.$store.dispatch("showSuccessMessage", "Saved Successfully");
      }
      catch(error){
        this.$store.dispatch("showErrorMessage", error);
      }
    },
    TryToAddRecord() {
      // if (this.records != null && this.records.length < 3) {
      this.ShowAddRecord = true;
      this.ShowEditRecord = false;
      // }
    },
    async ForceDeleteRecord() {
      try{
        this.ShowEditRecord = false;
        this.ShowAddRecord = false;

        this.$store.commit("AWAIT_RESPONSE", true);

        if(this.selectedRecord.image != null && this.selectedRecord.image != ""){
          //console.log(this.selectedRecord.image);
          await delete_file(this.selectedRecord.image);
          //console.log("Deleted image");
        }
        if(this.selectedRecord.thumbnail != null && this.selectedRecord.thumbnail != ""){
          await delete_file(this.selectedRecord.thumbnail);
          //console.log("Deleted thumbnail");
        }
        await this.$store.dispatch("records/destroy", {
          route:
            "/collections/" +
            this.curLocation.collection_id +
            "/locations/" +
            this.curLocation.id +
            "/records/" +
            this.selectedRecord.id,
          record: this.selectedRecord,
        });
        //console.log("Deleted record");
        this.$store.commit("AWAIT_RESPONSE", false);
        this.showModal = false;
      }
      catch(error){
        this.$store.dispatch("showErrorMessage", error);
        console.log(error);
      }
    },
    TryToDeleteRecord(record) {
      this.selectedRecord = record;
      this.showModal = true;
    },
    EditRecord(record) {
      this.selectedRecord = record;
      this.ShowEditRecord = true;
      this.ShowAddRecord = false;

      setTimeout(()=> {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      }, 100);
    },
    back() {
      this.$router.push("/collections/" + this.collectionId);
    },
    valueChanged() {
      this.canSubmit =
        this.$refs.addr.getValue() != "" ||
        (this.$refs.lat.getValue() != "" && this.$refs.long.getValue() != "");
    },
  },
};
</script>

<style scoped>
.panel-relative {
  position: relative;
}
.btn-right {
  position: absolute;
  right: 15px;
}
</style>