<template>
    <div class="section mt-6 has-background-dark" style="min-height:100vh">
        <button class="button is-pulled-right is-text is-outlined is-white" style="text-decoration: none" @click="this.$emit('close')">Close</button>

        <div class="columns is-centered">
            <div class="column is-4">
                <div id="horizontal" style="position:relative">
                    <!-- <img :src="this.url" class="is-overlay" style="width:16%;bottom-padding:100%;top:31.5%;left:48.3%"/> -->
                    <img src="../../assets/santa_monica_stickers_hor.png"/>
                    <img :src="this.url" style="position:absolute;width:16%;left:48.3%;transform:translateY(94%)">
                </div>
                <button class="button is-text has-text-white" @click="downloadFile('horizontal')">Download horizontal sticker</button>
            </div>
            <div class="column is-2" >
                <div id="vertical" style="position:relative;">
                    <!-- <img :src="this.url" class="is-overlay" style="width:34.5%;bottom-padding:100%;top:44%;left:32.5%"/> -->
                    <img src="../../assets/santa_monica_stickers_vert.png"/>
                    <img :src="this.url" style="position:absolute;width:34.5%;top:44%;left:32.5%">
                </div>
                <button class="button is-text has-text-white" @click="downloadFile('vertical')">Download vertical sticker</button>
            </div>
        </div>
    </div>
</template>

<script>
//import VueHtml2pdf from 'vue-html2pdf'
import * as htmlToImage from 'html-to-image'
export default {
    props:[
        "name",
        "qr_code"
    ],
    data(){
        return{
            url:""
        }
    },
    watch:{
        qr_code(){
            let blob = new Blob([this.qr_code], {type: 'image/svg+xml'});
            this.url = URL.createObjectURL(blob);
        }
    },
    methods:{
       downloadFile(id){
           var node = document.getElementById(id);
           var name = this.name;

           htmlToImage.toPng(node)
           .then(function(dataUrl){
               var a = document.createElement("a");
                a.href = dataUrl;
                a.download = id+"_"+name+".png";
                a.click();
           })
        }
    }
}
</script>